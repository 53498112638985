import React from 'react'
import SetTitle from '../../utils/set_title'
import PEDForm from "./PEDForm";

export default function PED() {
	SetTitle('PED Generator')
	return (
		<>
			<div className="bg-gradient-info secondary-page-title" >
				<div md={12} className="form-title">
					<i className="ni ni-align-left-2 text-white" style={{color: "white", fontSize: "35px"}}></i>
					<h1 className="page-title">
						PED Generator
						{/*<a href={BASE_URL + '/docs/ranking_tools/duplicate-keywords-gpt/'} target="_blank" style={{paddingLeft: "10px"}}>
							<i className="fa-solid fa-question-circle fa-xs text-white"></i>
						</a>*/}
					</h1>
				</div>
			</div>
			<div className="page-description">
				Partendo da un file excel di keyword fornite in input crea un topic scouting e genera un piano editoriale comprensivo di Abstract/Brief
				che poi può essere passato ai copy per la stesura dei contenuti (oppure a GPT in caso di scrittura articoli tramite AI).
			</div>

			<PEDForm/>
		</>
	)
}