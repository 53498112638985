import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/fontawesome-free-solid'

function ExpandButton({ expand, setExpand }) {
  return (
    <button
      type="button"
      className={'expand-button'}
      onClick={() => setExpand(!expand)}
    >
      <span>{expand ? 'Collassa' : 'Espandi'}</span>
      <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} />
    </button>
  )
}

export default ExpandButton
