import React from 'react'
import { Input } from 'reactstrap'

const TagsInput = (props) => {
  const { tags, value, onChange } = props

  const removeTags = (indexToRemove) => {
    props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)])
  }

  const addTags = (event) => {
    event.preventDefault()
    if (event.target.value !== '') {
      if (props.validator) {
        if (props.validator(event.target.value)) {
          //setTags([...tags, event.target.value])
          props.selectedTags([...tags, event.target.value])
          event.target.value = ''
        }
      } else {
        //setTags([...tags, event.target.value])
        props.selectedTags([...tags, event.target.value])
        event.target.value = ''
      }
    }
  }

  return (
    <div className="tags-input">
      <ul id="tags">
        {!!tags?.length &&
          tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <span className="tag-close-icon" onClick={() => removeTags(index)}>
                x
              </span>
            </li>
          ))}
      </ul>
      <Input
        className="form-control-alternative"
        type="text"
        value={value}
        onChange={onChange}
        onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
        onKeyUp={(event) => (event.key === 'Enter' ? addTags(event) : null)}
        placeholder={props.placeholder}
        required={tags.length ? false : true}
        onBlur={(event) => addTags(event)}
      />
    </div>
  )
}
export default TagsInput
