import Home from './main/Home'
import BigQueryExporter from './main/BigQueryExporterForm'
import LazyLoad from './main/LazyLoadForm'
import Scheduling from './main/Scheduling'
import AppTaggingTool from './AppTaggingTool'
import HistoricalReportForm from './containers/HistoricalReportForm/HistoricalReportForm'
import VolumeRetrieve from './containers/VolumeRetrieve/VolumeRetrieve'
import KeywordIdeas from './containers/KeywordIdeas/KeywordIdeas'
import DuplicateKeywordsGptForm from './containers/DuplicateKeywordsGptForm/DuplicateKeywordsGptForm'
import DimensionGroups from "./tagging_tool/containers/DimensionGroups/DimensionGroupsAdmin";
import CreateReport from "./tagging_tool/containers/CreateReport/CreateReport";
import BulkIndexing from './containers/BulkIndexing/BulkIndexingForm'
import SemRush from './containers/SemRush/SemRushForm'
import Listing from './tagging_tool/containers/Listing/Listing'
import HtmlCleaner from './containers/HtmlCleaner/HtmlCleanerForm'
import SiteUrlList from './containers/SeoMonitoring/SiteUrlList'
import { BASE_URL } from './utils'
import KeywordClustering from "./containers/KeywordClustering/KeywordClustering";
import PED from "./containers/PED/PED";

var routes = [
  {
    path: "https://form.asana.com/?k=OKCsOEte-p6k1sUtDAl6Mg&d=280257083226273",
    name: "Segnalazione",
    icon: "ni ni-notification-70 text-red",
    layout: "",
    private: true
  },
  {
    path: "",
    name: "Ranking Reports",
    icon: "ni ni-tv-2 text-primary",
    component: Home,
    layout: "/",
    private: false
  },
  {
    path: "scheduling",
    name: "Scheduled Ranking Reports",
    icon: "ni ni-time-alarm text-blue",
    component: Scheduling,
    layout: "/",
    private: false
  },
  // {
  //   path: "scheduling_index",
  //   name: "Scheduled Ranking Index",
  //   icon: "ni ni-books text-blue",
  //   component: SchedulingIndex,
  //   layout: "/",
  //   private: false
  // },
  {
    path: "keyword-ideas",
    name: "Keyword Ideas",
    icon: "ni ni-bulb-61 text-orange",
    component: KeywordIdeas,
    layout: "/",
    private: true
  },
  {
    path: "historical-retrieve",
    name: "Historical Retrieve",
    icon: "ni ni-compass-04 text-yellow",
    component: HistoricalReportForm,
    layout: "/",
    private: true
  },
  {
    path: "volume-retrieve",
    name: "Volume Retrieve",
    icon: "ni ni-chart-bar-32 text-red",
    component: VolumeRetrieve,
    layout: "/",
    private: true
  },
  {
    path: "duplicate-keywords-gpt",
    name: "Duplicate Keywords GPT",
    icon: "ni ni-key-25 text-info",
    component: DuplicateKeywordsGptForm,
    layout: "/",
    private: true
  },
  {
    path: "keyword-clustering",
    name: "Keyword Clustering",
    icon: "ni ni-atom text-info",
    component: KeywordClustering,
    layout: "/",
    private: true
  },
  {
    path: "ped-generator",
    name: "PED Generator",
    icon: "ni ni-align-left-2 text-info",
    component: PED,
    layout: "/",
    private: true
  },
  {
    path: "lazy-load",
    name: "Lazy Load",
    icon: "ni ni-button-pause text-purple",
    component: LazyLoad,
    layout: "/",
    private: true
  },
  {
    path: "bigquery-exporter",
    name: "Bigquery Exporter",
    icon: "ni ni-folder-17 text-green",
    component: BigQueryExporter,
    layout: "/",
    private: false
  },
  {
    path: "bulk-indexing",
    name: "Bulk Indexing",
    icon: "fa fa-rss text-info",
    component: BulkIndexing,
    layout: "/",
    private: true
  },
  {
    path: "semrush",
    name: "SemRush",
    icon: "fa fa-fire text-orange",
    component: SemRush,
    layout: "/",
    private: true
  },
  {
    path: "html-cleaner",
    name: "HtmlCleaner",
    icon: "fa fa-paint-brush text-green",
    component: HtmlCleaner,
    layout: "/",
    private: true
  },
  {
    path: "seo-monitoring/site-urls",
    name: "Seo Monitoring",
    icon: "ni ni-chart-bar-32 text-green",
    component: SiteUrlList,
    layout: "/",
    private: true
  },
  {
    path: "https://intentions.webranking.biz/",
    name: "Keyword Intentions",
    icon: "ni ni-bullet-list-67 text-green",
    layout: "",
    private: true
  },
  {
    path: BASE_URL+"/docs/",
    name: "Docs",
    icon: "ni ni-support-16 text-blue",
    layout: "",
    private: true
  },
  {
    path: "reporting",
    name: "Tagging Tool",
    icon: "ni ni-tag text-pink",
    component: AppTaggingTool,
    layout: "/",
    private: true,
    childrens: [
      {
        path: "reporting",
        name: "Lista Report",
        component: Listing,
        layout: "/"
      },
      {
        path: "reporting-create",
        name: "Creazione Report",
        component: CreateReport,
        layout: "/"
      },
      {
        path: "reporting-dimension-groups",
        name: "Dimensioni e Regole Salvate",
        component: DimensionGroups,
        layout: "/"
      },
      {
        path: "docs/documentazione_seo/tagging-tool-wiki/",
        name: "Guida",
        layout: ""
      },
    ]
  }
];
export default routes;
